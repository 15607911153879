import React, { useContext } from "react";

import TransitionType from "./TransitionType";
import isTouchDevice from "../SE-Component/Tooltip";
import embLabels from "../../config/emb-labels-en.json";
import { InformationPageContext } from "../CustomHooks/ContextApi";

const SelectUseCase = () => {
  const {
    projectType,
    siteType,
    informationToolTIP,
    disabledFeatures,
    optimizationEngine,
    otherOptimizationStrategy,
    gridSettings,
    handleOptimizationChange,
    handleOtherOptimizationChange,
    handleOnGridFeatureChange,
    handleModeChange,
  } = useContext(InformationPageContext);

  const isOnGridFeaturesEnabled = () => {
    const status =
      optimizationEngine === "EMA" &&
      gridSettings.remoteMonitoringControl &&
      siteType !== embLabels.PV_HYBRIDIZATION
        ? "enabled"
        : "disabled";

    return status === "enabled" ? true : false;
  };
  return (
    <div className="row mt-2">
      <div className="col-md-12">
        <div className="col-md-12 border-radius-5px ">
          <div className="row">
            {[
              embLabels.ISLANDABLE,
              embLabels.GRID_CONNECTED,
              embLabels.PV_HYBRIDIZATION,
            ].includes(siteType) && (
              <div
                className={
                  siteType === embLabels.ISLANDABLE
                    ? disabledFeatures.disableCloud
                      ? "col-sm-4 col-md-4 col-lg-4 col-xl-4 se-block-disabled"
                      : "col-sm-4 col-md-4 col-lg-4 col-xl-4 "
                    : "col-md-6 "
                }
              >
                {[embLabels.ISLANDABLE, embLabels.GRID_CONNECTED].includes(
                  siteType
                ) &&
                  gridSettings.remoteMonitoringControl && (
                    <div className="row">
                      <div className="col-md-12 mt-1">
                        <span>Optimization Engine</span>
                      </div>
                    </div>
                  )}

                {[embLabels.ISLANDABLE, embLabels.GRID_CONNECTED].includes(
                  siteType
                ) &&
                  gridSettings.remoteMonitoringControl && (
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                className="custom-control-input islandableRadio asCompatible"
                                name="optimizationEngine"
                                id="emaOptimization"
                                value="EMA"
                                checked={optimizationEngine === "EMA"}
                                onChange={handleOptimizationChange}
                              />
                              <label
                                className="custom-control-label line-height-25"
                                htmlFor="emaOptimization"
                              >
                                EMA optimization
                              </label>
                            </div>
                          </div>
                        </div>

                        <div
                          className="row se-block-disabled"
                          style={{ display: "none" }}
                        >
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-1 ">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                className="custom-control-input islandableRadio asCompatible"
                                name="optimizationEngine"
                                id="otherOptimization"
                                value="OTHER"
                                checked={optimizationEngine === "OTHER"}
                                onChange={handleOptimizationChange}
                              />
                              <label
                                className="custom-control-label line-height-25"
                                htmlFor="otherOptimization"
                              >
                                Other optimization
                              </label>
                            </div>
                          </div>
                          {optimizationEngine === "OTHER" && (
                            <div className="row se-block-disabled">
                              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ml-5 mt-1  mb-2 ">
                                <div className="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    className="custom-control-input islandableRadio asCompatible"
                                    name="otherOptimizationStrategy"
                                    id="autogridOptimization"
                                    value="AUTOGRID"
                                    checked={
                                      otherOptimizationStrategy === "AUTOGRID"
                                    }
                                    onChange={handleOtherOptimizationChange}
                                  />
                                  <label
                                    className="custom-control-label line-height-25"
                                    htmlFor="autogridOptimization"
                                  >
                                    Autogrid
                                  </label>
                                  <se-tooltip
                                    action={isTouchDevice() ? "click" : "hover"}
                                    position="right"
                                  >
                                    <se-icon
                                      option="raised"
                                      color="secondary"
                                      size="small"
                                      slot="tooltip"
                                    >
                                      information_circle
                                    </se-icon>
                                    <se-tooltip-content>
                                      To change the default EMA optimization
                                      server to autogrid
                                    </se-tooltip-content>
                                  </se-tooltip>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                {optimizationEngine === "EMA" && (
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-1">
                      <span>On grid optimization features </span>
                    </div>
                  </div>
                )}

                {isOnGridFeaturesEnabled() && (
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input asCompatible"
                          name="demandCharge"
                          id="customControlValidation1"
                          value={gridSettings.demandCharge}
                          checked={gridSettings.demandCharge}
                          onChange={handleOnGridFeatureChange}
                          disabled
                        />
                        <label
                          className="custom-control-label line-height-25"
                          htmlFor="customControlValidation1"
                        >
                          Demand charge optimization
                        </label>
                        <se-tooltip
                          action={isTouchDevice() ? "click" : "hover"}
                          position="right"
                        >
                          <se-icon
                            option="raised"
                            color="secondary"
                            size="small"
                            slot="tooltip"
                          >
                            information_circle
                          </se-icon>
                          <se-tooltip-content>
                            {informationToolTIP.demandChargeTooltipData}
                          </se-tooltip-content>
                        </se-tooltip>
                      </div>
                    </div>
                  </div>
                )}

                {isOnGridFeaturesEnabled() && (
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input asCompatible"
                          name="tariffManagement"
                          id="customControlValidation2"
                          value={gridSettings.tariffManagement}
                          checked={gridSettings.tariffManagement}
                          onChange={handleOnGridFeatureChange}
                          disabled
                        />
                        <label
                          className="custom-control-label line-height-25"
                          htmlFor="customControlValidation2"
                        >
                          Time of Use Tariff optimization
                        </label>
                        <se-tooltip
                          action={isTouchDevice() ? "click" : "hover"}
                          position="right"
                        >
                          <se-icon
                            option="raised"
                            color="secondary"
                            size="small"
                            slot="tooltip"
                          >
                            information_circle
                          </se-icon>
                          <se-tooltip-content>
                            {informationToolTIP.tariffManagementTooltipData}
                          </se-tooltip-content>
                        </se-tooltip>
                      </div>
                    </div>
                  </div>
                )}

                {isOnGridFeaturesEnabled() && (
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input asCompatible"
                          name="selfConsumption"
                          id="customControlValidation3"
                          value={gridSettings.selfConsumption}
                          checked={gridSettings.selfConsumption}
                          onChange={handleOnGridFeatureChange}
                        />
                        <label
                          className="custom-control-label line-height-25"
                          htmlFor="customControlValidation3"
                        >
                          Self consumption optimization
                        </label>
                        <se-tooltip
                          action={isTouchDevice() ? "click" : "hover"}
                          position="right"
                        >
                          <se-icon
                            option="raised"
                            color="secondary"
                            size="small"
                            slot="tooltip"
                          >
                            information_circle
                          </se-icon>
                          <se-tooltip-content>
                            {informationToolTIP.selfConsumptionTooltipData}
                          </se-tooltip-content>
                        </se-tooltip>
                      </div>
                    </div>
                  </div>
                )}

                {isOnGridFeaturesEnabled() && (
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input asCompatible"
                          name="exportOptimization"
                          id="customControlValidation4"
                          value={gridSettings.exportOptimization}
                          checked={gridSettings.exportOptimization}
                          onChange={handleOnGridFeatureChange}
                        />
                        <label
                          className="custom-control-label line-height-25"
                          htmlFor="customControlValidation4"
                        >
                          Export predictive optimization
                        </label>
                        <se-tooltip
                          action={isTouchDevice() ? "click" : "hover"}
                          position="right"
                        >
                          <se-icon
                            option="raised"
                            color="secondary"
                            size="small"
                            slot="tooltip"
                          >
                            information_circle
                          </se-icon>
                          <se-tooltip-content>
                            {informationToolTIP.exportOptimizationTooltipData}
                          </se-tooltip-content>
                        </se-tooltip>
                      </div>
                    </div>
                  </div>
                )}

                {optimizationEngine === "EMA" && (
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input asCompatible"
                          name="noExport"
                          id="customControlValidation5"
                          value={gridSettings.noExport}
                          checked={gridSettings.noExport}
                          onChange={handleOnGridFeatureChange}
                        />
                        <label
                          className="custom-control-label line-height-25"
                          htmlFor="customControlValidation5"
                        >
                          Export real time optimization
                        </label>
                        <se-tooltip
                          action={isTouchDevice() ? "click" : "hover"}
                          position="right"
                        >
                          <se-icon
                            option="raised"
                            color="secondary"
                            size="small"
                            slot="tooltip"
                          >
                            information_circle
                          </se-icon>
                          <se-tooltip-content>
                            {informationToolTIP.exportRealTimeTooltipData}
                          </se-tooltip-content>
                        </se-tooltip>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {[embLabels.STANDARD_MICROGRID].includes(projectType) &&
              [embLabels.ISLANDABLE, embLabels.OFF_GRID].includes(siteType) && (
                <div
                  className={`col-sm-3 col-md-3 col-lg-3 col-xl-3 border-left ${
                    siteType === embLabels.OFF_GRID ||
                    disabledFeatures.disableOpertaions
                      ? "se-block-disabled"
                      : ""
                  }`}
                >
                  <div className="row">
                    <div className="col-md-12 pr-0">
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-1">
                          <span>
                            Asset Configuration{" "}
                            <span className="se-mandatory">*</span>{" "}
                          </span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1 ">
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="customRadio4"
                              name="mode"
                              className="custom-control-input islandableRadio asCompatible"
                              value="NO_FUEL"
                              checked={
                                gridSettings.mode === "NO_FUEL" ? true : false
                              }
                              onChange={handleModeChange}
                            />
                            <label
                              className="custom-control-label line-height-25"
                              htmlFor="customRadio4"
                            >
                              BESS main, No Genset
                            </label>
                            <se-tooltip
                              action={isTouchDevice() ? "click" : "hover"}
                              position="right"
                            >
                              <se-icon
                                option="raised"
                                color="secondary"
                                slot="tooltip"
                              >
                                information_circle
                              </se-icon>
                              <se-tooltip-content>
                                {informationToolTIP.noFuelTooltipData}
                              </se-tooltip-content>
                            </se-tooltip>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="customRadio5"
                              name="mode"
                              className="custom-control-input islandableRadio asCompatible"
                              value="FUEL_ERASER"
                              checked={
                                gridSettings.mode === "FUEL_ERASER"
                                  ? true
                                  : false
                              }
                              onChange={handleModeChange}
                            />
                            <label
                              className="custom-control-label line-height-25"
                              htmlFor="customRadio5"
                            >
                              BESS main, Genset as backup
                            </label>
                            <se-tooltip
                              action={isTouchDevice() ? "click" : "hover"}
                              position="right"
                            >
                              <se-icon
                                option="raised"
                                color="secondary"
                                slot="tooltip"
                              >
                                information_circle
                              </se-icon>
                              <se-tooltip-content>
                                {informationToolTIP.fuelEraserTooltipData}
                              </se-tooltip-content>
                            </se-tooltip>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1 ">
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="customRadio6"
                              name="mode"
                              className="custom-control-input islandableRadio asCompatible"
                              value="FUEL_SAVER"
                              checked={
                                gridSettings.mode === "FUEL_SAVER"
                                  ? true
                                  : false
                              }
                              onChange={handleModeChange}
                            />
                            <label
                              className="custom-control-label line-height-25"
                              htmlFor="customRadio6"
                            >
                              Genset main, BESS grid tied
                            </label>
                            <se-tooltip
                              action={isTouchDevice() ? "click" : "hover"}
                              position="right"
                            >
                              <se-icon
                                option="raised"
                                color="secondary"
                                slot="tooltip"
                              >
                                information_circle
                              </se-icon>
                              <se-tooltip-content>
                                {informationToolTIP.fuelSaverTooltipData}
                              </se-tooltip-content>
                            </se-tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {[embLabels.STANDARD_MICROGRID].includes(projectType) &&
              [embLabels.ISLANDABLE, embLabels.OFF_GRID].includes(siteType) && (
                <TransitionType />
              )}

            {siteType === embLabels.OFF_GRID && (
              <div className="col-sm-4 se-block-disabled mt-6">
                <div className="blur mt-5">
                  <span>Coming Soon</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectUseCase;
