import React, { useCallback, useRef, useState, useEffect } from "react";

import DemandCharge from "mg-ui-dc";

import OperationUser from "./DesignPrograms/OperationUser";
import Alarming from "./DesignPrograms/Alarming";
import SiteAddUser from "../components/component/SiteAddUser";
import SelectTemplate from "../components/component/SelectTemplate";
import OnGridOptimization from "./DesignPrograms/OnGridOptimization";
import OffGridManagement from "./DesignPrograms/OffGridManagement";
import DisabledUseCase from "./DesignPrograms/DisabledUseCase";

import userScope from "./CustomHooks/userScope";
import useMountEffect from "./CustomHooks/useMountEffect";
import Snackbar from "./SE-Component/Snackbar";
import AlertIcon from "./SE-Component/AlertIcon";
import { DesignProgramProvider } from "./CustomHooks/ContextApi";

import embLabels from "../config/emb-labels-en.json";

import _ from "lodash";

import DcAlertLabels from "../config/DEMAND_CHARGE_ALERT_LIST.json";

//*api call
import ApiSession from "../api/ApiSession";
const apiSession = new ApiSession();

const DesignProgramHook = props => {
  const [UserScope] = userScope();

  const [loading, setLoading] = useState(true);
  const [pvCurtailmentExport, setPvCurtailmentExport] = useState(false);
  const [pvCurtailment, setPvCurtailment] = useState(true);
  const [utilityLSIEnable, setUtilityLSIEnable] = useState(true);
  const [gensetLSIEnable, setGensetLSIEnable] = useState(true);
  const [loadsDisplay, setLoadsDisplay] = useState(false);
  const [pvBreakers, setPvBreakers] = useState(true);
  const [exportManagement, setExportManagement] = useState(true);
  const [, setBessHibernatingActivated] = useState(false);
  const [siteCreated, setSiteCreated] = useState(false);
  const [templateSelected, setTemplateSelected] = useState(false);

  const [demandChargeOutput, setDemandChargeOutput] = useState(null);

  const [siteType, setSiteType] = useState("");
  const [clientType, setClientType] = useState("");
  const [architectureType, setArchitectureType] = useState("");
  const [siteGroup, setsiteGroup] = useState(localStorage.getItem("pname"));
  const [selectedAlaram, setSelectedAlaram] = useState("NO_ALARM");
  const [BESSChargingEnabled, setBESSChargingEnabled] = useState("Y");
  const [BESSHibernateEnabled, setBESSHibernateEnabled] = useState("N");
  const [values, setValues] = useState("");
  const [, setUpdate] = useState("");
  const [newTemplateName, setNewTemplateName] = useState("");
  const [maxExportPower, setMaxExportPower] = useState(0);
  const [gensetLoad, setGensetLoad] = useState(0);
  const [maxGensetLoad, setMaxGensetLoad] = useState(0);
  const [powerReservePercentageBESS, setPowerReservePercentageBESS] =
    useState(0);
  const [powerReservePercentageGenset, setPowerReservePercentageGenset] =
    useState(20);
  const [, setProgramId] = useState(0);

  const [siteUsers, setSiteUsers] = useState([]);
  const [emailUsers, setEmailUsers] = useState([]);
  const [loads, setLoads] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [domain] = useState([0, 100]);

  const [demandChargeData, setDemandChargeData] = useState({
    periodsList: [],
    demandChargeUseCaseSelection: "",
    penaltyCoefficient: 0.11,
    penaltyCalculationFormula: "FRENCH",
    dcUsedPower: "instantaneous",
    dcAverageDuration: 0,
    demandChargeMaxPower: {
      yearEnable: true,
      yearPeakCost: "",
    },
    demandChargeYearly: {
      ratchetYearlyPercentage: 100,
    },
  });
  const [defaultsMinMax, setDefaultsMinMax] = useState({});
  const [gridDetails, setGridDetails] = useState({
    gridOutrageDetection: 2,
    gridOutrageDetectionCase: "",
    gridDetectionTimer: 5000,
    lostDetectionTimer: 500,
  });
  const [gridSettings, setGridSettings] = useState({
    demandCharge: false,
    tariffManagement: false,
    selfConsumption: false,
    exportOptimization: false,
    noExport: false,
    remoteMonitoringControl: false,
    mode: "FUEL_SAVER",
    gensetBattery: "OPEN",
    gridTransitionOnline: "OPEN",
    gridTransitionOutage: "OPEN",
    manualSwitch: "OPEN",
    isIslandable: false,
    stormPreparedness: false,
  });
  const [configuredStatus, setConfiguredStatus] = useState({
    dcConfiguredStatus: 0,
    scConfiguredStatus: 0,
    emConfiguredStatus: 0,
    lsConfiguredStatus: 0,
    tmConfiguredStatus: 0,
    gofpConfiguredStatus: 0,
    bclConfiguredStatus: 0,
    bhConfiguredStatus: 0,
    configuredBlocks: 0,
  });
  const [gensetDetails, setGensetDetails] = useState({
    gensetAvailabilityStatus: false,
    gensetBackfeedProtection: true,
    gensetBackfeedProtectionStatus: "Y",
  });
  const [genset, setGenset] = useState({
    powerReserve: 0,
    max_power: 0,
    changed: "0",
  });
  const [BESS, setBESS] = useState({
    cycle_limit: "100",
    soc_essential: "20",
    powerReserve: 0,
    cycle_period: "7",
    soc_emergency: "15",
    soc_hysteresis: "15",
    soc_max: "90",
    soc_min: "10",
    changed: "0",
  });
  const [batteryDetails, setBatteryDetails] = useState({
    batteryBuffering: false,
    batteryBufferingExport: false,
    batteryBufferingStatus: false,
  });
  const [loadShedding, setLoadShedding] = useState({
    loadShedding: true,
    loadSheddingStatus: "Y",
  });
  const [loadList, setLoadList] = useState({
    emergencyList: [],
    essentialList: [],
    nonEssentialList: [],
  });
  const [dndDetails, setDndDetails] = useState({
    draggedLoad: {},
    draggedLoadFrom: "",
  });
  const [reconnectionTimers, setReconnectionTimers] = useState({
    reconnectionTimer: 0,
    reconnection2Timer: 0,
  });
  const [BESSCharging, setBESSCharging] = useState({
    BESS_charging_limitation_enabled: true,
    BESS_PV_limitation_enable: true,
    BESS_PV_disconnection: true,
  });
  const [popupStatus, setPopupStatus] = useState({
    addUserPopUpStatus: false,
    deleteSiteUserConfirmPopUpStatus: false,
    inviteUserToAlaramStatus: false,
    deleteUserFromAlarmStatus: false,
    loadSheddingConfirmTruePopUpStatus: false,
    loadSheddingConfirmPopUpStatus: false,
    gensetOptimisationConfirmPopUpStatus: false,
    bessChargingConfirmPopUpStatus: false,
    besshibernateConfirmPopUpStatus: false,
    utilityPvOpeningConfirmpopUpSattus: false,
    gensetPvOpeningConfirmpopUpSattus: false,
    templatePopUpStatus: false,
    newTemplateConfirmPopUpStatus: false,
  });
  const [popupInfo, setPopupInfo] = useState({
    deleteSiteUserId: "",
    deleteEmailId: "",
  });
  const [snackBar, setSnackBar] = useState({ type: "", message: "" });
  const [alert, setAlert] = useState({
    message: "",
    alertPopUpStatus: false,
    title: "",
    isRoute: false,
    routePath: "",
  });
  const [runEffects, setRunEffects] = useState({
    loadPriority: false,
  });

  const snackBarRef = useRef(null);

  useMountEffect(() => {
    localStorage.setItem("programDataModified", false);
    localStorage.setItem("toBeSavedAsDraft", false);

    if (gridDetails.gridOutrageDetection === 2) {
      setGridDetails(prevState => ({
        ...prevState,
        gridOutrageDetectionCase: "HWS",
      }));
    } else {
      setGridDetails(prevState => ({
        ...prevState,
        gridOutrageDetectionCase: "CB",
      }));
    }
    if (sessionStorage.getItem("projectId") === "null") {
      setLoading(false);
      let { history } = props;
      history.push({
        pathname: "/information",
      });
    } else {
      let demandChargeDataNew = { ...demandChargeData };
      setDemandChargeData(demandChargeDataNew);

      setTimeout(() => getProgramData(sessionStorage.getItem("projectId")), 2000);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (localStorage.getItem("toBeSavedAsDraft") === "true") {
        saveDraftGenerateProgram(false);
      }
    };
  });

  useMountEffect(() => {
    if (runEffects.loadPriority) {
      updateLoadsPriority();
    }
  }, [runEffects]);

  useEffect(() => {
    const seApp = document.getElementsByClassName("se-app-body");
    const seContainer = document.getElementsByTagName("se-container");

    if (seApp.length && seContainer.length) {
      seContainer[0].removeAttribute("position");
      seApp[0].style.position = "relative";
    }

    return () => {
      if (seApp.length && seContainer.length) {
        seApp[0].style.position = "absolute";
        seContainer[0].setAttribute("position", "absolute");
      }
    };
  }, [siteType]);

  const getProgramData = projectId => {
    setLoading(true);
    apiSession
      .getProgramData(projectId)
      .then(response => {
        localStorage.setItem("programPage", response.programPage);

        const defaultsData = {};
        for (let data of response.defaultValues) {
          defaultsData[data["param"]] = data.value;
        }
        setDefaultsMinMax(defaultsData);
        setSiteCreated(response.siteCreated);

        if (
          response.siteType === embLabels.PV_HYBRIDIZATION ||
          response.siteType === embLabels.ISLANDABLE_GSX
        ) {
          setGensetLSIEnable(false);
          setUtilityLSIEnable(false);
        }

        if (response.programId) {
          setGridDetails(prevState => ({
            ...prevState,
            gridOutrageDetectionCase:
              response.gridOutrageDetection === 1 ? "CB" : "HWS",
            gridDetectionTimer: parseInt(response.gridDetectionTimer),
            lostDetectionTimer: parseInt(response.lostDetectionTimer),
            gridOutrageDetection: response.gridOutrageDetection,
          }));
          setPvCurtailment(response.pvCurtailment);
          setPvBreakers(response.pvBreakers);
          setsiteGroup(response.siteGroup);

          setBatteryDetails(prevState => ({
            ...prevState,
            batteryBuffering: response.batteryBuffering,
            batteryBufferingExport: response.batteryBufferingExport,
          }));
          setReconnectionTimers(prevState => ({
            ...prevState,
            reconnectionTimer: response.reconnectionTimer,
            reconnection2Timer: response.reconnection2Timer,
          }));

          setExportManagement(response.exportManagement);

          setLoadShedding(prevState => ({
            ...prevState,
            loadShedding: response.loadShedding,
            loadSheddingStatus: response.loadShedding === true ? "Y" : "N",
          }));
          setGensetLSIEnable(response.gensetLSIEnable);
          setUtilityLSIEnable(response.utilityLSIEnable);
          setGensetLoad(response.gensetLoad);
          setPvCurtailmentExport(response.pvCurtailmentExport);
          setMaxExportPower(response.maxExportPower);
          setSelectedAlaram(
            response.alarm === null ? "NO_ALARM" : response.alarm.alarmType
          );
          setEmailUsers(response.alarm === null ? [] : response.alarm.emails);
          setProgramId(response.programId);
          setBessHibernatingActivated(response.bessHibernatingActivated);

          configuredAll();
        } else {
          setPvBreakers(true);
          setConfiguredStatus(prevState => ({
            ...prevState,
            configuredBlocks: 0,
          }));

          setPvCurtailment(true);
          setsiteGroup(localStorage.getItem("pname"));
          setGridDetails(prevState => ({
            ...prevState,
            gridOutrageDetection: response.gridOutrageDetection,
            gridOutrageDetectionCase:
              response.gridOutrageDetection === 1 ? "CB" : "HWS",
            gridDetectionTimer: parseInt(response.gridDetectionTimer),
            lostDetectionTimer: parseInt(response.lostDetectionTimer),
          }));

          setBatteryDetails(prevState => ({
            ...prevState,
            batteryBuffering: false,
          }));
          setReconnectionTimers(prevState => ({
            ...prevState,
            reconnectionTimer: response.reconnectionTimer,
            reconnection2Timer: response.reconnection2Timer,
          }));
        }

        if (
          response.gridSettings.mode === "FUEL_ERASER" ||
          response.gridSettings.mode === "NO_FUEL"
        ) {
          setBESSHibernateEnabled(
            response.bessHibernatingActivated === true ? "Y" : "N"
          );
        } else {
          setBESSHibernateEnabled("N");
          setBessHibernatingActivated(false);
        }

        //* DC
        if (response.demandCharge !== null) {
          let demandChargeNew = Object.assign({}, demandChargeData);
          demandChargeNew = JSON.parse(response.demandCharge);
          demandChargeNew.currency = localStorage.getItem("currency");
          setDemandChargeData(demandChargeNew);
          setDemandChargeOutput(demandChargeNew);
        } else {
          let demandChargeNew = Object.assign({}, demandChargeData);
          setDemandChargeOutput(demandChargeNew);
          configuredStatusNot("dcConfiguredStatus");
        }

        //* BESS
        let newBESS = _.cloneDeep(BESS);
        if (response.BESS) {
          newBESS = response.BESS;
        }

        if (response.programId) {
          //* do nothing
        } else {
          newBESS.soc_emergency =
            parseInt(newBESS.soc_min) + parseInt(newBESS.soc_emergency);
          newBESS.soc_essential =
            parseInt(newBESS.soc_min) + parseInt(newBESS.soc_essential);
          newBESS.soc_hysteresis =
            parseInt(newBESS.soc_min) + parseInt(newBESS.soc_hysteresis);
        }

        setBESS(newBESS);
        calculateLoadSliderDisplayValues(newBESS);

        if (parseInt(newBESS.powerReserve) === 0) {
          setPowerReservePercentageBESS(20);
          let reservePower = Math.round(
            parseFloat(newBESS.max_power / 100) * parseFloat(20)
          );

          setBESS(prevState => ({
            ...prevState,
            powerReserve: reservePower >= 0 ? reservePower : 0,
          }));
        } else if (parseInt(newBESS.powerReserve) > 0) {
          let reservePercentageBess = (
            (parseFloat(newBESS.powerReserve) / parseFloat(newBESS.max_power)) *
            100
          ).toFixed(2);
          setPowerReservePercentageBESS(
            reservePercentageBess >= 0
              ? Math.round(Number(reservePercentageBess))
              : 0
          );
        }

        setSiteUsers(response.emaSiteUsers);
        setGridSettings(response.gridSettings);
        setSiteType(response.siteType);
        setClientType(response.clientType);
        setArchitectureType(response.architecture);
        assignLoads(response);
        getProjectUsecase(
          sessionStorage.getItem("projectId"),
          response.gridSettings
        );
        setGensetDetails(prevState => ({
          ...prevState,
          gensetBackfeedProtection: response.gensetBackfeedProtection,
          gensetBackfeedProtectionStatus:
            response.gensetBackfeedProtection === true ? "Y" : "N",
        }));

        //* GENSET
        let newGenset = _.cloneDeep(genset);
        newGenset = response.Genset ? response.Genset : newGenset;
        if (response.Genset) {
          setGenset(newGenset);
        }

        setMaxGensetLoad(newGenset.max_power);
        if (parseInt(newGenset.powerReserve) === 0) {
          setPowerReservePercentageGenset(20);
          let reservePower = Math.round(
            parseFloat(newGenset.max_power / 100) * parseFloat(20)
          );

          setGenset(prevState => ({
            ...prevState,
            powerReserve: reservePower >= 0 ? reservePower : 0,
          }));
        } else if (parseInt(newGenset.powerReserve) > 0) {
          let reservePercentage = (
            (parseFloat(newGenset.powerReserve) /
              parseFloat(newGenset.max_power)) *
            100
          ).toFixed(2);
          setPowerReservePercentageGenset(
            reservePercentage >= 0 ? Math.round(Number(reservePercentage)) : 0
          );
        }

        if (response.bessChargingLimitation !== null) {
          let parsingResult = JSON.parse(response.bessChargingLimitation);
          let BESSChargingNew = Object.assign({}, BESSCharging);
          BESSChargingNew = parsingResult;
          setBESSCharging(BESSChargingNew);
          setBESSChargingEnabled(
            parsingResult.BESS_charging_limitation_enabled === true ? "Y" : "N"
          );
        }

        if (
          response.gridSettings.demandCharge ||
          response.gridSettings.tariffManagement
        ) {
          getDcTemplates();
        }

        if (response.programId) {
          if (
            response.gridSettings.noExport === false &&
            response.gridSettings.exportOptimization === true
          ) {
            setPvCurtailmentExport(false);
            setUtilityLSIEnable(false);
          }

          if (parseInt(newGenset.changed) === 0) {
            setGensetLoad(
              Math.round(parseFloat(newGenset.max_power / 100) * 30)
            );
          } else {
            setGensetLoad(
              Math.round(
                parseFloat(newGenset.max_power / 100) *
                  parseFloat(response.loadPercentage)
              )
            );
          }
        } else {
          if (
            (response.gridSettings.noExport === true &&
              response.gridSettings.exportOptimization === true) ||
            response.gridSettings.noExport === true
          ) {
            setPvCurtailmentExport(true);
            setUtilityLSIEnable(true);
          } else {
            setPvCurtailmentExport(false);
            setUtilityLSIEnable(false);
          }

          const newGensetLoad = Math.round(
            parseFloat(newGenset.max_power / 100) * 30
          );

          setGensetLoad(newGensetLoad);
          if (
            response.siteType === embLabels.PV_HYBRIDIZATION ||
            response.siteType === embLabels.ISLANDABLE_GSX
          ) {
            setGensetLSIEnable(false);
            setUtilityLSIEnable(false);
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setPvCurtailment(true);
        setPvBreakers(true);
        setGridDetails(prevState => ({
          ...prevState,
          gridOutrageDetectionCase: "HWS",
          gridDetectionTimer: defaultsMinMax.goodGridDetectionTimerDefault,
          lostDetectionTimer: defaultsMinMax.gridLostTimerDefault,
          gridOutrageDetection: 2,
        }));
        setGensetDetails(prevState => ({
          ...prevState,
          gensetBackfeedProtection: false,
          gensetBackfeedProtectionStatus: "N",
        }));
        setsiteGroup(localStorage.getItem("pname"));
        setLoading(false);
      });
  };

  const getDcTemplates = () => {
    setLoading(true);

    apiSession
      .getTemplate()
      .then(json => {
        setTemplates(json);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const assignLoads = response => {
    let loadsFromAPI = [];
    let loadsEM = [];
    let loadsES = [];
    let loadsNES = [];
    let loadManagementTabStatus = false;

    response.resources.forEach(resourcesData => {
      if (
        parseInt(resourcesData.attributes[0].motorized.attributeValue) === 1
      ) {
        loadManagementTabStatus = true;
      }

      if (
        parseInt(resourcesData.attributes[0].loadCategory.attributeValue) === 0
      ) {
        loadsFromAPI.push(resourcesData);
      } else if (
        parseInt(resourcesData.attributes[0].loadCategory.attributeValue) === 1
      ) {
        loadsEM.push(resourcesData);
      } else if (
        parseInt(resourcesData.attributes[0].loadCategory.attributeValue) === 2
      ) {
        loadsES.push(resourcesData);
      } else if (
        parseInt(resourcesData.attributes[0].loadCategory.attributeValue) === 3
      ) {
        loadsNES.push(resourcesData);
      }
    });
    setLoadsDisplay(loadManagementTabStatus);

    loadsEM.sort(function (a, b) {
      return (
        a.attributes[0].loadPriority.attributeValue -
        b.attributes[0].loadPriority.attributeValue
      );
    });
    loadsES.sort(function (a, b) {
      return (
        a.attributes[0].loadPriority.attributeValue -
        b.attributes[0].loadPriority.attributeValue
      );
    });
    loadsNES.sort(function (a, b) {
      return (
        a.attributes[0].loadPriority.attributeValue -
        b.attributes[0].loadPriority.attributeValue
      );
    });
    setLoads(loadsFromAPI);
    setLoadList(prevState => ({
      ...prevState,
      emergencyList: loadsEM,
      essentialList: loadsES,
      nonEssentialList: loadsNES,
    }));
  };

  const calculateLoadSliderDisplayValues = newBESS => {
    let defaultValues = [];
    defaultValues.push(parseInt(newBESS.soc_min));
    defaultValues.push(parseInt(newBESS.soc_emergency));
    defaultValues.push(parseInt(newBESS.soc_essential));
    defaultValues.push(parseInt(newBESS.soc_hysteresis));
    defaultValues.push(parseInt(newBESS.soc_max));
    setValues(defaultValues.slice());
    setUpdate(defaultValues.slice());
  };

  const getProjectUsecase = (projectId, gridSettings) => {
    apiSession
      .getselectedUsecase(projectId)
      .then(response => {
        let bessResult = -1;
        let gensetResult = -1;
        if (response.length) {
          bessResult = response.indexOf(embLabels.BESS);
          gensetResult = response.indexOf(embLabels.GENSET);

          if (gensetResult > -1) {
            setGensetDetails(prevState => ({
              ...prevState,
              gensetAvailabilityStatus: true,
              gensetBackfeedProtection: true,
            }));
          } else {
            setGensetDetails(prevState => ({
              ...prevState,
              gensetAvailabilityStatus: false,
              gensetBackfeedProtection: false,
              gensetBackfeedProtectionStatus: "N",
            }));
          }

          if (bessResult > -1) {
            setBatteryDetails(prevState => ({
              ...prevState,
              batteryBufferingStatus: true,
              batteryBuffering: true,
            }));

            if (
              gridSettings.noExport === true ||
              gridSettings.exportOptimization === true
            ) {
              setBatteryDetails(prevState => ({
                ...prevState,
                batteryBufferingExport: true,
              }));
            } else {
              setBatteryDetails(prevState => ({
                ...prevState,
                batteryBufferingExport: false,
              }));
            }
          } else {
            setBatteryDetails(prevState => ({
              ...prevState,
              batteryBuffering: false,
              batteryBufferingStatus: false,
              batteryBufferingExport: false,
            }));
          }
        } else {
          setBatteryDetails(prevState => ({
            ...prevState,
            batteryBuffering: false,
            batteryBufferingStatus: false,
            batteryBufferingExport: false,
          }));
        }
      })
      .catch(() => {
        //do nothing
      });
  };

  //* Add users to site

  const handleChangeSiteGroup = e => {
    setsiteGroup(e.target.value);
    localStorage.setItem("programDataModified", true);
  };

  const addUserSite = () =>
    setPopupStatus(prevState => ({ ...prevState, addUserPopUpStatus: true }));

  const closeAddUser = () =>
    setPopupStatus(prevState => ({ ...prevState, addUserPopUpStatus: false }));

  const addUserToSiteList = data => {
    const status = siteUsers.findIndex(
      siteUser => siteUser.emailId === data.emailAddress
    );
    if (status !== -1) {
      setPopupStatus(prevState => ({
        ...prevState,
        addUserPopUpStatus: false,
      }));
      callSnackbar(`error`, `User already invited`);
    } else {
      setLoading(true);
      setPopupStatus(prevState => ({
        ...prevState,
        addUserPopUpStatus: false,
      }));
      apiSession
        .addSiteUser({
          projectId: sessionStorage.getItem("projectId"),
          emailId: data.emailAddress,
        })
        .then(response => {
          setLoading(false);
          callSnackbar("success", response.status);
          getSiteUsers();
        })
        .catch(error => {
          setLoading(false);
          if (error.response !== undefined) {
            if (error.response.status === 400) {
              callSnackbar("error", error.response.data.message);
            } else if (error.response.status === 500) {
              callSnackbar(
                "error",
                "Unable to find user in EMA or EMB, Try later"
              );
            } else {
              callSnackbar("error", "Error in add user to site");
            }
          } else {
            callSnackbar("error", "Error in add user to site");
          }
        });
    }
  };

  const getSiteUsers = () => {
    setLoading(true);
    apiSession
      .getProgramData(sessionStorage.getItem("projectId"))
      .then(response => {
        setSiteUsers(response.emaSiteUsers);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const removeSiteUserConfirmationPrompt = siteUserId => {
    setPopupInfo(prevState => ({ ...prevState, deleteSiteUserId: siteUserId }));
    setPopupStatus(prevState => ({
      ...prevState,
      deleteSiteUserConfirmPopUpStatus: true,
    }));
  };

  const closeDeleteSiteUserPopUp = () => {
    setPopupInfo(prevState => ({ ...prevState, deleteSiteUserId: "" }));

    setPopupStatus(prevState => ({
      ...prevState,
      deleteSiteUserConfirmPopUpStatus: false,
    }));
  };

  const removeSiteUserConfirm = () => {
    setPopupStatus(prevState => ({
      ...prevState,
      deleteSiteUserConfirmPopUpStatus: false,
    }));
    if (popupInfo.deleteSiteUserId !== "") {
      setLoading(true);
      apiSession
        .deleteSiteUser(popupInfo.deleteSiteUserId)
        .then(() => {
          setLoading(false);
          callSnackbar("success", "User deleted sucessfully");
          getSiteUsers();
        })
        .catch(error => {
          setLoading(false);
          if (error.response !== undefined) {
            if (parseInt(error.response.status) === 400) {
              callSnackbar("error", error.response.data.message);
            } else if (error.response.status === 504) {
              callSnackbar("error", "User not found");
            } else {
              callSnackbar("error", "Error in deletion");
            }
          } else {
            callSnackbar("error", "Error in deletion");
          }
        });
    }
  };

  //* Alarm

  const alaramChangeHanlder = e => setSelectedAlaram(e.target.value);

  const addEmailToAlaram = () => {
    if (
      ["BASIC_ALARM", "ADVANCED_ALARM"].includes(selectedAlaram) &&
      emailUsers.length > 2
    ) {
      const newSelectedName =
        selectedAlaram === "BASIC_ALARM" ? "Basic alarm" : "Advanced alarm";
      callSnackbar(
        "error",
        `${newSelectedName} usecase can have maximum three email address`
      );
    } else {
      setPopupStatus(prevState => ({
        ...prevState,
        inviteUserToAlaramStatus: true,
      }));
    }
  };

  const closeInviteEmailToAlaram = () =>
    setPopupStatus(prevState => ({
      ...prevState,
      inviteUserToAlaramStatus: false,
    }));

  const inviteUserToAlaramList = data => {
    const UserPresent = emailUsers.includes(data.emailAddress);
    if (UserPresent) {
      setPopupStatus(prevState => ({
        ...prevState,
        inviteUserToAlaramStatus: false,
      }));
      callSnackbar("error", "User already added");
    } else {
      setEmailUsers(prevState => [...prevState, data.emailAddress]);
      setPopupStatus(prevState => ({
        ...prevState,
        inviteUserToAlaramStatus: false,
      }));
      callSnackbar("success", "User added successfully");
    }
  };

  const removeUserFromAlarmStatus = userData => {
    setPopupInfo(prevState => ({ ...prevState, deleteEmailId: userData }));
    setPopupStatus(prevState => ({
      ...prevState,
      deleteUserFromAlarmStatus: true,
    }));
  };

  const closeDeleteInviteUserpopup = () => {
    setPopupInfo(prevState => ({ ...prevState, deleteEmailId: "" }));

    setPopupStatus(prevState => ({
      ...prevState,
      deleteUserFromAlarmStatus: false,
    }));
  };

  const removeEmailFromAlaram = () => {
    const emailUsersNew = emailUsers.filter(
      user => user !== popupInfo.deleteEmailId
    );
    setPopupStatus(prevState => ({
      ...prevState,
      deleteUserFromAlarmStatus: false,
    }));
    setPopupInfo(prevState => ({ ...prevState, deleteEmailId: "" }));
    setEmailUsers(emailUsersNew);
    callSnackbar("success", "User deleted successfuly");
  };

  //* ongrid

  const demandChargeDataCallback = data => {
    let newDemandCharge = {};
    newDemandCharge = data;
    setDemandChargeOutput(newDemandCharge);
    configuredStatusModified("dcConfiguredStatus");
    setTemplateSelected(false);
  };

  const enableTemplatePopUp = () =>
    setPopupStatus(prevState => ({ ...prevState, templatePopUpStatus: true }));

  const disableTemplatePopUp = () =>
    setPopupStatus(prevState => ({ ...prevState, templatePopUpStatus: false }));

  const selectTemplate = data => {
    let newDemandCharge = {};
    newDemandCharge = JSON.parse(data);
    setPopupStatus(prevState => ({ ...prevState, templatePopUpStatus: false }));
    setTemplateSelected(true);
    setDemandChargeOutput(newDemandCharge);
  };
  const saveNewTemplate = () => {
    let result = null;
    let dcData = _.cloneDeep(demandChargeOutput);

    result = DemandCharge.validateDemandCharge(dcData);
    if (result !== null) {
      callSnackbar("error", DcAlertLabels[result]);
    } else {
      setPopupStatus(prevState => ({
        ...prevState,
        newTemplateConfirmPopUpStatus: true,
      }));
    }
  };
  const closeNewTemplatePopUp = () => {
    setNewTemplateName("");
    setPopupStatus(prevState => ({
      ...prevState,
      newTemplateConfirmPopUpStatus: false,
    }));
  };
  const handlenewTemplate = e => {
    const { value } = e.target;
    setNewTemplateName(value);
  };

  const saveTemplate = () => {
    setLoading(true);
    setPopupStatus(prevState => ({
      ...prevState,
      newTemplateConfirmPopUpStatus: false,
    }));

    const demandCharge = {
      title: newTemplateName,
      demandCharge: JSON.stringify(demandChargeOutput),
    };

    apiSession
      .createTemplate(demandCharge)
      .then(() => {
        callSnackbar("success", `Template created successfully`);
        setLoading(false);

        getDcTemplates();
      })
      .catch(() => {
        callSnackbar("error", `Error in saving the template`);
        setLoading(false);
      });
  };

  const exportManagementChange = () => {
    setAlertMessage(
      `Alert`,
      false,
      ``,
      `For changing the use case, go back to information page and make your choice.`
    );
  };

  const handleChange = e => {
    //const onlyNumber = /^[0-9]?\d*\.?\d*$/;
    const onlyNumberInt = /^[0-9]?\d*$/;
    const onlyNumberIntNegative = /^(-)?([0-9]?\d*)$/;

    const { name, value } = e.target;

    switch (name) {
      case "maxExportPower":
        if (onlyNumberIntNegative.test(e.target.value)) {
          setMaxExportPower(value);
        }
        break;

      case "reconnectionTimer":
      case "reconnection2Timer":
        if (onlyNumberInt.test(value)) {
          setReconnectionTimers(prevState => ({
            ...prevState,
            [name]: value,
          }));
        }

        break;

      case "lostDetectionTimer":
      case "gridDetectionTimer":
        if (onlyNumberInt.test(value)) {
          setGridDetails(prevState => ({
            ...prevState,
            [name]: value,
          }));
        }

        break;

      case "gensetLoad":
        if (genset.max_power === "" || parseInt(genset.max_power) === 0) {
          callSnackbar("error", "Genset max power is not configured in SLD");
        } else {
          if (onlyNumberInt.test(value)) {
            setGensetLoad(value);
          }
        }

        break;

      default:
        break;
    }
  };

  const handleCheckBoxChange = e => {
    const { name, checked } = e.target;
    switch (name) {
      case "batteryBuffering":
      case "batteryBufferingExport":
        if (batteryDetails.batteryBufferingStatus !== true) {
          setBatteryDetails(prevState => ({
            ...prevState,
            [name]: checked,
          }));
        }
        break;
      case "pvCurtailmentExport":
        setPvCurtailmentExport(checked);
        break;
      case "pvCurtailment":
        setPvCurtailment(checked);
        break;
      case "gensetLSIEnable":
        setGensetLSIEnable(checked);
        break;
      case "BESS_PV_limitation_enable":
      case "BESS_PV_disconnection":
        let BESSCharge = { ...BESSCharging };
        BESSCharge[name] = checked;
        setBESSCharging(BESSCharge);
        break;
      default:
        break;
    }
  };

  const handlepPVOpeningPopup = e => {
    const { name, checked } = e.target;
    if (name === "utilityLSIEnable") {
      setUtilityLSIEnable(checked);
      if (
        (siteType === embLabels.PV_HYBRIDIZATION ||
          (siteType === embLabels.ISLANDABLE_GSX &&
            architectureType === embLabels.PV_GENSET_ATS)) &&
        checked
      ) {
        setPopupStatus(prevState => ({
          ...prevState,
          utilityPvOpeningConfirmpopUpSattus: true,
        }));
      }
    }
    if (name === "gensetLSIEnable") {
      setGensetLSIEnable(checked);
      if (
        (siteType === embLabels.PV_HYBRIDIZATION ||
          (siteType === embLabels.ISLANDABLE_GSX &&
            architectureType === embLabels.PV_GENSET_ATS)) &&
        checked
      ) {
        setPopupStatus(prevState => ({
          ...prevState,
          gensetPvOpeningConfirmpopUpSattus: true,
        }));
      }
    }
  };

  const cancelUtilityPvopenPopUp = () => {
    setPopupStatus(prevState => ({
      ...prevState,
      utilityPvOpeningConfirmpopUpSattus: false,
    }));
    setUtilityLSIEnable(false);
  };

  const confirmUtilityPVOpen = () => {
    setPopupStatus(prevState => ({
      ...prevState,
      utilityPvOpeningConfirmpopUpSattus: false,
    }));
    setUtilityLSIEnable(true);
  };

  const cancelGensetPvopenPopUp = () => {
    setPopupStatus(prevState => ({
      ...prevState,
      gensetPvOpeningConfirmpopUpSattus: false,
    }));
    setGensetLSIEnable(false);
  };

  const confirmGensetPVOpen = () => {
    setPopupStatus(prevState => ({
      ...prevState,
      gensetPvOpeningConfirmpopUpSattus: false,
    }));
    setGensetLSIEnable(true);
  };

  //* off grid

  const loadSheddingChangeHandler = e => {
    if (e.target.value === "Y")
      setPopupStatus(prevState => ({
        ...prevState,
        loadSheddingConfirmTruePopUpStatus: true,
      }));

    if (e.target.value === "N")
      setPopupStatus(prevState => ({
        ...prevState,
        loadSheddingConfirmPopUpStatus: true,
      }));
  };

  const cancelLoadSheddingConfirm = () =>
    setPopupStatus(prevState => ({
      ...prevState,
      loadSheddingConfirmPopUpStatus: false,
    }));

  const acceptLoadSheddingConfirm = () => {
    setPopupStatus(prevState => ({
      ...prevState,
      loadSheddingConfirmPopUpStatus: false,
    }));
    setLoadShedding(prevState => ({
      ...prevState,
      loadShedding: false,
      loadSheddingStatus: "N",
    }));
    configuredStatusModified("lsConfiguredStatus");
  };

  const cancelLoadSheddingTrueConfirm = () =>
    setPopupStatus(prevState => ({
      ...prevState,
      loadSheddingConfirmTruePopUpStatus: false,
    }));

  const acceptLoadSheddingTrueConfirm = () => {
    setPopupStatus(prevState => ({
      ...prevState,
      loadSheddingConfirmTruePopUpStatus: false,
    }));
    setLoadShedding(prevState => ({
      ...prevState,
      loadShedding: true,
      loadSheddingStatus: "Y",
    }));
    configuredStatusModified("lsConfiguredStatus");
  };

  const onDrag = (event, loadData) => {
    event.preventDefault();
    setDndDetails(prevState => ({
      ...prevState,
      draggedLoad: loadData,
      draggedLoadFrom: "",
    }));
  };

  const onDrop = (e, loadType) => {
    const { draggedLoad, draggedLoadFrom } = dndDetails;
    const { emergencyList, essentialList, nonEssentialList } = loadList;
    configuredStatusModified("lsConfiguredStatus");
    switch (loadType) {
      case "emergency":
        if (draggedLoadFrom === "") {
          draggedLoad.attributes[0].loadCategory.attributeValue = 1;
          const newemergencyList = [...emergencyList, draggedLoad];
          setLoadList(prevState => ({
            ...prevState,
            emergencyList: newemergencyList,
          }));
          const newLoadList = loads.filter(
            load => load.resourceId !== draggedLoad.resourceId
          );
          setLoads(newLoadList);
          setDndDetails(prevState => ({ ...prevState, draggedLoad: {} }));

          const runEffectsNew = { loadPriority: true };
          setRunEffects(runEffectsNew);
        } else if (draggedLoadFrom === "essential") {
          draggedLoad.attributes[0].loadCategory.attributeValue = 1;
          const newemergencyList = [...emergencyList, draggedLoad];

          const newEssentialList = essentialList.filter(
            essentialData => essentialData.resourceId !== draggedLoad.resourceId
          );
          setLoadList(prevState => ({
            ...prevState,
            emergencyList: newemergencyList,
            essentialList: newEssentialList,
          }));
          setDndDetails(prevState => ({
            ...prevState,
            draggedLoad: {},
            draggedLoadFrom: "",
          }));

          const runEffectsNew = { loadPriority: true };
          setRunEffects(runEffectsNew);
        } else if (draggedLoadFrom === "nonEssential") {
          draggedLoad.attributes[0].loadCategory.attributeValue = 1;
          const newemergencyList = [...emergencyList, draggedLoad];

          const newNonEssentialList = nonEssentialList.filter(
            nonEssentialData =>
              nonEssentialData.resourceId !== draggedLoad.resourceId
          );
          setLoadList(prevState => ({
            ...prevState,
            emergencyList: newemergencyList,
            nonEssentialList: newNonEssentialList,
          }));
          setDndDetails(prevState => ({
            ...prevState,
            draggedLoad: {},
            draggedLoadFrom: "",
          }));

          const runEffectsNew = { loadPriority: true };
          setRunEffects(runEffectsNew);
        }
        break;

      case "essential":
        if (draggedLoadFrom === "") {
          draggedLoad.attributes[0].loadCategory.attributeValue = 2;
          const newEssentialList = [...essentialList, draggedLoad];
          setLoadList(prevState => ({
            ...prevState,
            essentialList: newEssentialList,
          }));
          const newLoadList = loads.filter(
            load => load.resourceId !== draggedLoad.resourceId
          );
          setLoads(newLoadList);
          setDndDetails(prevState => ({ ...prevState, draggedLoad: {} }));

          const runEffectsNew = { loadPriority: true };
          setRunEffects(runEffectsNew);
        } else if (draggedLoadFrom === "emergency") {
          draggedLoad.attributes[0].loadCategory.attributeValue = 2;
          const newEssentialList = [...essentialList, draggedLoad];

          const newEmergencyList = emergencyList.filter(
            emergencyData => emergencyData.resourceId !== draggedLoad.resourceId
          );

          setLoadList(prevState => ({
            ...prevState,
            essentialList: newEssentialList,
            emergencyList: newEmergencyList,
          }));
          setDndDetails(prevState => ({
            ...prevState,
            draggedLoad: {},
            draggedLoadFrom: "",
          }));

          const runEffectsNew = { loadPriority: true };
          setRunEffects(runEffectsNew);
        } else if (draggedLoadFrom === "nonEssential") {
          draggedLoad.attributes[0].loadCategory.attributeValue = 2;
          const newEssentialList = [...essentialList, draggedLoad];

          const newNonEssentialData = nonEssentialList.filter(
            nonEssentialData =>
              nonEssentialData.resourceId !== draggedLoad.resourceId
          );

          setLoadList(prevState => ({
            ...prevState,
            essentialList: newEssentialList,
            nonEssentialList: newNonEssentialData,
          }));
          setDndDetails(prevState => ({
            ...prevState,
            draggedLoad: {},
            draggedLoadFrom: "",
          }));

          const runEffectsNew = { loadPriority: true };
          setRunEffects(runEffectsNew);
        }
        break;
      case "nonEssential":
        if (draggedLoadFrom === "") {
          draggedLoad.attributes[0].loadCategory.attributeValue = 3;
          const newNonEssentialList = [...nonEssentialList, draggedLoad];
          setLoadList(prevState => ({
            ...prevState,
            nonEssentialList: newNonEssentialList,
          }));
          const newLoadList = loads.filter(
            load => load.resourceId !== draggedLoad.resourceId
          );
          setLoads(newLoadList);
          setDndDetails(prevState => ({ ...prevState, draggedLoad: {} }));

          const runEffectsNew = { loadPriority: true };
          setRunEffects(runEffectsNew);
        } else if (draggedLoadFrom === "emergency") {
          draggedLoad.attributes[0].loadCategory.attributeValue = 3;
          const newNonEssentialList = [...nonEssentialList, draggedLoad];
          const newEmergencyList = emergencyList.filter(
            emergencyData => emergencyData.resourceId !== draggedLoad.resourceId
          );
          setLoadList(prevState => ({
            ...prevState,
            nonEssentialList: newNonEssentialList,
            emergencyList: newEmergencyList,
          }));

          setDndDetails(prevState => ({
            ...prevState,
            draggedLoad: {},
            draggedLoadFrom: "",
          }));

          const runEffectsNew = { loadPriority: true };
          setRunEffects(runEffectsNew);
        } else if (draggedLoadFrom === "essential") {
          draggedLoad.attributes[0].loadCategory.attributeValue = 3;
          const newNonEssentialList = [...nonEssentialList, draggedLoad];
          const newEssentialList = essentialList.filter(
            essentialData => essentialData.resourceId !== draggedLoad.resourceId
          );
          setLoadList(prevState => ({
            ...prevState,
            nonEssentialList: newNonEssentialList,
            essentialList: newEssentialList,
          }));

          setDndDetails(prevState => ({
            ...prevState,
            draggedLoad: {},
            draggedLoadFrom: "",
          }));

          const runEffectsNew = { loadPriority: true };
          setRunEffects(runEffectsNew);
        }
        break;
      case "loads":
        draggedLoad.attributes[0].loadCategory.attributeValue = 0;
        draggedLoad.attributes[0].loadPriority.attributeValue = 0;
        if (draggedLoadFrom === "emergency") {
          const nonEmergencyList = emergencyList.filter(
            emergencyData => emergencyData.resourceId !== draggedLoad.resourceId
          );
          setLoadList(prevState => ({
            ...prevState,
            emergencyList: nonEmergencyList,
          }));
          const newLoadList = [...loads, draggedLoad];
          setLoads(newLoadList);
          setDndDetails(prevState => ({
            ...prevState,
            draggedLoad: {},
            draggedLoadFrom: "",
          }));
        } else if (draggedLoadFrom === "essential") {
          const newEssentialData = essentialList.filter(
            essentialData => essentialData.resourceId !== draggedLoad.resourceId
          );
          setLoadList(prevState => ({
            ...prevState,
            essentialList: newEssentialData,
          }));
          const newLoadList = [...loads, draggedLoad];
          setLoads(newLoadList);
          setDndDetails(prevState => ({
            ...prevState,
            draggedLoad: {},
            draggedLoadFrom: "",
          }));
        } else if (draggedLoadFrom === "nonEssential") {
          const newNonEssentialList = nonEssentialList.filter(
            nonEssentialData =>
              nonEssentialData.resourceId !== draggedLoad.resourceId
          );
          setLoadList(prevState => ({
            ...prevState,
            nonEssentialList: newNonEssentialList,
          }));
          const newLoadList = [...loads, draggedLoad];
          setLoads(newLoadList);
          setDndDetails(prevState => ({
            ...prevState,
            draggedLoad: {},
            draggedLoadFrom: "",
          }));
        }
        break;
      default:
        break;
    }
  };

  const onDragFrom = (event, loadData, loadFrom) => {
    event.preventDefault();
    setDndDetails(prevState => ({
      ...prevState,
      draggedLoad: loadData,
      draggedLoadFrom: loadFrom,
    }));
  };

  const onDragOver = event => event.preventDefault();

  const updateLoadsPriority = caseType => {
    let i = 1;
    let newEmergencyList = [...loadList.emergencyList];
    newEmergencyList.forEach((newEmergencyListData, index) => {
      newEmergencyList[index].attributes[0].loadPriority.attributeValue = i;
      i = i + 1;
    });

    let ies = newEmergencyList.length + 1;
    let newEssentialList = [...loadList.essentialList];
    newEssentialList.forEach((newEssentialListData, index) => {
      newEssentialList[index].attributes[0].loadPriority.attributeValue = ies;
      ies = ies + 1;
    });

    let ine = newEmergencyList.length + newEssentialList.length + 1;
    let newNonEssentialList = [...loadList.nonEssentialList];
    newNonEssentialList.forEach((newNonEssentialListData, index) => {
      newNonEssentialList[index].attributes[0].loadPriority.attributeValue =
        ine;
      ine = ine + 1;
    });
    setLoadList(prevState => ({
      ...prevState,
      emergencyList: newEmergencyList,
      essentialList: newEssentialList,
      nonEssentialList: newNonEssentialList,
    }));
  };

  const onUpdate = update => setUpdate(update);

  const onChange = values => {
    setValues(values);
    setBESS(prevState => ({
      ...prevState,
      soc_emergency: values[1],
      soc_essential: values[2],
      soc_hysteresis: values[3],
    }));
  };

  const handleChangeBESS = e => {
    const { name, value } = e.target;
    const onlyNumberInt = /^[0-9]?\d*$/;
    let dataValue = "";
    if (onlyNumberInt.test(value)) {
      dataValue = value;
    } else {
      dataValue = BESS[name];
    }
    if (e.target.name === "powerReserve") {
      calulateAndSetpowerReservePercentageBESS(dataValue);
    }

    let newBESS = Object.assign({}, BESS);
    newBESS[name] = dataValue;

    if (
      name === "soc_emergency" ||
      name === "soc_essential" ||
      name === "soc_hysteresis"
    ) {
      newBESS[name] = dataValue === "" ? 0 : dataValue;
      calculateLoadSliderDisplayValues(newBESS);
    }
    setBESS(newBESS);
  };

  const calulateAndSetpowerReservePercentageBESS = value => {
    let reservePercentage = (
      (parseFloat(value) / parseFloat(BESS.max_power)) *
      100
    ).toFixed(2);
    setPowerReservePercentageBESS(
      reservePercentage >= 0 ? Math.round(Number(reservePercentage)) : 0
    );

    if (BESS.max_power === "") {
      callSnackbar("error", "BESS max power is not configured in SLD");
    }
  };

  const handleChangeGenset = e => {
    const { name, value } = e.target;
    const onlyNumberInt = /^[0-9]?\d*$/;
    let dataValue = "";
    if (onlyNumberInt.test(value)) {
      dataValue = value;
    } else {
      dataValue = genset[name];
    }
    let newGenset = Object.assign({}, genset);
    newGenset[e.target.name] = dataValue;
    if (name === "powerReserve") {
      calulateAndSetpowerReservePercentageGenset(dataValue);
    }

    setGenset(newGenset);
  };

  const calulateAndSetpowerReservePercentageGenset = value => {
    let reservePercentage = (
      (parseFloat(value) / parseFloat(genset.max_power)) *
      100
    ).toFixed(2);
    setPowerReservePercentageGenset(
      reservePercentage >= 0 ? Math.round(Number(reservePercentage)) : 0
    );

    if (genset.max_power === "") {
      callSnackbar("error", "Genset max power is not configured in SLD");
    }
  };

  const handleChangePowerReservePercentage = e => {
    const { value, name } = e.target;
    const onlyNumberInt = /^[0-9]?\d*$/;
    let dataValue = "";
    if (onlyNumberInt.test(value)) {
      dataValue = value;
    } else {
      if (name === "powerReservePercentageBESS") {
        dataValue = powerReservePercentageBESS;
      } else if (name === "powerReservePercentageGenset") {
        dataValue = powerReservePercentageGenset;
      }
    }
    if (name === "powerReservePercentageBESS") {
      setPowerReservePercentageBESS(dataValue);
    } else if (name === "powerReservePercentageGenset") {
      setPowerReservePercentageGenset(dataValue);
    }
    if (name === "powerReservePercentageBESS") {
      let reservePower = Math.round(
        parseFloat(BESS.max_power / 100) * parseFloat(dataValue)
      );
      if (BESS.max_power === "") {
        callSnackbar("error", "BESS max power is not configured in SLD");
      }
      setBESS(prevState => ({
        ...prevState,
        powerReserve: reservePower >= 0 ? reservePower : 0,
      }));
    } else if (name === "powerReservePercentageGenset") {
      let reservePower = Math.round(
        parseFloat(genset.max_power / 100) * parseFloat(dataValue)
      );
      if (genset.max_power === "") {
        callSnackbar("error", "Genset max power is not configured in SLD");
      }
      setGenset(prevState => ({
        ...prevState,
        powerReserve: reservePower >= 0 ? reservePower : 0,
      }));
    }
  };

  const handleRadioButtonChange = e => {
    const { name, value } = e.target;

    switch (name) {
      case "gridOutrageDetectionCase":
        setGridDetails(prevState => ({
          ...prevState,
          gridOutrageDetectionCase: value,
        }));
        break;

      case "gensetBackfeedProtectionStatus":
        setGensetDetails(prevState => ({
          ...prevState,
          gensetBackfeedProtectionStatus: value,
        }));
        break;

      case "BESSChargingEnabled":
        setBESSChargingEnabled(value);
        break;

      case "BESSHibernateEnabled":
        setBESSHibernateEnabled(value);
        break;

      default:
        break;
    }
  };

  const handleGensetOptimizationConfirm = () =>
    setPopupStatus(prevState => ({
      ...prevState,
      gensetOptimisationConfirmPopUpStatus: true,
    }));

  const confirmGensetOptimisation = () => {
    setGensetDetails(prevState => ({
      ...prevState,
      gensetBackfeedProtectionStatus: "Y",
    }));
    setPopupStatus(prevState => ({
      ...prevState,
      gensetOptimisationConfirmPopUpStatus: false,
    }));
  };

  const cancelGensetOptimisation = () => {
    setPopupStatus(prevState => ({
      ...prevState,
      gensetOptimisationConfirmPopUpStatus: false,
    }));
  };

  const openBessChargingpopup = () =>
    setPopupStatus(prevState => ({
      ...prevState,
      bessChargingConfirmPopUpStatus: true,
    }));

  const confirmBessCharging = () => {
    setBESSChargingEnabled("Y");
    setPopupStatus(prevState => ({
      ...prevState,
      bessChargingConfirmPopUpStatus: false,
    }));
  };

  const cancelBessCharging = () =>
    setPopupStatus(prevState => ({
      ...prevState,
      bessChargingConfirmPopUpStatus: false,
    }));

  const openBessHibernatepopup = () =>
    setPopupStatus(prevState => ({
      ...prevState,
      besshibernateConfirmPopUpStatus: true,
    }));

  const confirmBessHibernate = () => {
    setBESSHibernateEnabled("Y");
    setPopupStatus(prevState => ({
      ...prevState,
      besshibernateConfirmPopUpStatus: false,
    }));
  };

  const cancelBessHibernate = () =>
    setPopupStatus(prevState => ({
      ...prevState,
      besshibernateConfirmPopUpStatus: false,
    }));

  //* general
  const configuredStatusNot = data =>
    setConfiguredStatus(prevState => ({ ...prevState, [data]: 0 }));

  const configuredStatusModified = data => {
    localStorage.setItem("programDataModified", true);
    setConfiguredStatus(prevState => ({ ...prevState, [data]: 2 }));
  };
  const configuredAll = () => {
    setConfiguredStatus(prevState => ({
      ...prevState,
      dcConfiguredStatus: 1,
      emConfiguredStatus: 1,
      scConfiguredStatus: 1,
      lsConfiguredStatus: 1,
      tmConfiguredStatus: 1,
      gofpConfiguredStatus: 1,
      bclConfiguredStatus: 1,
      bhConfiguredStatus: 1,
      configuredBlocks: 1,
    }));
  };

  const validateProgram = () => {
    const loadsCheck = loads.filter(
      load => load.attributes[0].motorized.attributeValue !== "0"
    );

    /* validate Demand Charge Data  start*/
    let result = null;
    let dcData = _.cloneDeep(demandChargeOutput);
    if (gridSettings.demandCharge || gridSettings.tariffManagement) {
      result = DemandCharge.validateDemandCharge(dcData);
    }
    if (
      (gridSettings.demandCharge || gridSettings.tariffManagement) &&
      result !== null
    ) {
      callSnackbar("error", DcAlertLabels[result]);
    } else if (siteGroup === "") {
      callSnackbar("error", "Site Group is mandatory");
    } else if (
      ["BASIC_ALARM", "ADVANCED_ALARM"].includes(selectedAlaram) &&
      emailUsers.length === 0
    ) {
      const newSelectedName =
        selectedAlaram === "BASIC_ALARM" ? "Basic alarm" : "Advanced alarm";

      callSnackbar(
        "error",
        `${newSelectedName} usecase requires minimum one email address`
      );
    } else if (
      (gridSettings.noExport === true ||
        gridSettings.exportOptimization === true) &&
      (maxExportPower.toString() === "" ||
        parseInt(maxExportPower) > 999 ||
        parseInt(maxExportPower) < -999)
    ) {
      callSnackbar(
        "error",
        "Export Management - Targeted Max Export power is mandatory & Range (-999 < Max Export power <999)"
      );
    } else if (
      BESS.soc_emergency.toString() === "" ||
      parseInt(BESS.soc_emergency) > 100
    ) {
      callSnackbar(
        "error",
        "Load shedding - SoC Emergency is mandatory & should be less than 100"
      );
    } else if (
      BESS.soc_essential.toString() === "" ||
      parseInt(BESS.soc_essential) > 100
    ) {
      callSnackbar(
        "error",
        "Load shedding - SoC Essential is mandatory & should be less than 100"
      );
    } else if (loadShedding.loadShedding === true && loadsCheck.length !== 0) {
      callSnackbar("error", "Load shedding - Loads are not prioritized");
    } else if (
      loadShedding.loadShedding === true &&
      reconnectionTimers.reconnectionTimer.toString() === ""
    ) {
      callSnackbar(
        "error",
        "Load shedding - Delay before reconnecting a load after last shedding (s) is mandatory"
      );
    } else if (
      loadShedding.loadShedding === true &&
      (parseInt(reconnectionTimers.reconnectionTimer) <
        defaultsMinMax.delayReconnectingMin ||
        parseInt(reconnectionTimers.reconnectionTimer) >
          defaultsMinMax.delayReconnectingMax)
    ) {
      callSnackbar(
        `error`,
        `Load shedding - Delay before reconnecting a load after last shedding  should be in range (${defaultsMinMax.delayReconnectingMin}-${defaultsMinMax.delayReconnectingMax} s`
      );
    } else if (
      loadShedding.loadShedding === true &&
      reconnectionTimers.reconnection2Timer.toString() === ""
    ) {
      callSnackbar(
        "error",
        "Load shedding - Delay before reconnecting a load after last load reconnection (s) is mandatory and between 0-600s"
      );
    } else if (
      loadShedding.loadShedding === true &&
      (parseInt(reconnectionTimers.reconnection2Timer) <
        defaultsMinMax.delayReconnecting2Min ||
        parseInt(reconnectionTimers.reconnection2Timer) >
          defaultsMinMax.delayReconnecting2Max)
    ) {
      callSnackbar(
        "error",
        "Load shedding - Delay before reconnecting a load after last load reconnection should be in range (" +
          defaultsMinMax.delayReconnecting2Min +
          "-" +
          defaultsMinMax.delayReconnecting2Max +
          " s)"
      );
    } else if (
      loadShedding.loadShedding === true &&
      ((batteryDetails.batteryBufferingStatus === true &&
        BESS.powerReserve.toString() === "") ||
        (parseFloat(BESS.powerReserve) / parseFloat(BESS.max_power)) * 100 > 50)
    ) {
      callSnackbar(
        "error",
        "Load shedding - BESS power reserve is mandatory & should not exceed more than 50% of max power"
      );
    } else if (
      loadShedding.loadShedding === true &&
      siteType === embLabels.ISLANDABLE &&
      ((gensetDetails.gensetAvailabilityStatus === true &&
        genset.powerReserve.toString() === "") ||
        (parseFloat(genset.powerReserve) / parseFloat(genset.max_power)) * 100 >
          50)
    ) {
      callSnackbar(
        "error",
        "Load shedding - Genset power reserve is mandatory & should not exceed more than 50% of max power"
      );
    } else if (gridDetails.lostDetectionTimer.toString() === "") {
      callSnackbar(
        "error",
        "Transition Management - Grid Lost Detection Timer is mandatory"
      );
    } else if (
      parseInt(gridDetails.lostDetectionTimer) <
        defaultsMinMax.gridLostTimerMin ||
      parseInt(gridDetails.lostDetectionTimer) > defaultsMinMax.gridLostTimerMax
    ) {
      callSnackbar(
        "error",
        "Transition Management - Grid Lost Detection Timer should be in range(" +
          defaultsMinMax.gridLostTimerMin +
          "-" +
          defaultsMinMax.gridLostTimerMax +
          " ms)"
      );
    } else if (gridDetails.gridDetectionTimer.toString() === "") {
      callSnackbar(
        "error",
        "Transition Management - Good Grid Detection Timer is mandatory"
      );
    } else if (
      parseInt(gridDetails.gridDetectionTimer) <
        defaultsMinMax.goodGridDetectionTimerMin ||
      parseInt(gridDetails.gridDetectionTimer) >
        defaultsMinMax.goodGridDetectionTimerMax
    ) {
      callSnackbar(
        "error",
        "Transition Management - Good Grid Detection Timer should be in range(" +
          defaultsMinMax.goodGridDetectionTimerMin +
          "-" +
          defaultsMinMax.goodGridDetectionTimerMax +
          " s)"
      );
    } else if (
      gensetDetails.gensetBackfeedProtection === true &&
      (gensetLoad.toString() === "" ||
        parseInt(gensetLoad) === 0 ||
        (parseFloat(gensetLoad) / parseFloat(maxGensetLoad)) * 100 < 10.0 ||
        (parseFloat(gensetLoad) / parseFloat(maxGensetLoad)) * 100 > 100.0) &&
      (siteType === embLabels.ISLANDABLE ||
        siteType === embLabels.PV_HYBRIDIZATION ||
        (siteType === embLabels.ISLANDABLE_GSX &&
          architectureType === embLabels.PV_GENSET_ATS))
    ) {
      callSnackbar(
        "error",
        "Genset Feedback Protection - Targeted Min Genset load is mandatory & should be in range  10% to 100% of Max Power"
      );
    } else {
      setLoading(true);
      let resources = [];
      let resourcesLoad = [];
      let resourcesEm = [];
      let resourcesEs = [];
      let resourcesNEs = [];
      loads.forEach(LData => {
        resourcesLoad.push(LData);
      });
      loadList.emergencyList.forEach(EMData => {
        resourcesEm.push(EMData);
      });
      loadList.essentialList.forEach(ESData => {
        resourcesEs.push(ESData);
      });
      loadList.nonEssentialList.forEach(NESData => {
        resourcesNEs.push(NESData);
      });
      resources.push(...resourcesLoad);
      resources.push(...resourcesEm);
      resources.push(...resourcesEs);
      resources.push(...resourcesNEs);

      let newBESS = Object.assign({}, BESS);
      newBESS.changed = 1;
      let newGenset = Object.assign({}, genset);
      newGenset.changed = 1;

      let programDataJson = {
        projectId: sessionStorage.getItem("projectId"),
        siteGroup: siteGroup,
        gridDetectionTimer: gridDetails.gridDetectionTimer,
        lostDetectionTimer: gridDetails.lostDetectionTimer,
        gridOutrageDetection:
          gridDetails.gridOutrageDetectionCase === "CB" ? 1 : 2,
        pvCurtailment: pvCurtailment,
        pvBreakers: pvBreakers,
        batteryBuffering: batteryDetails.batteryBuffering,
        uc_dc: false,
        uc_tm: false,
        uc_sc: false,
        uc_no_export: false,
        exportManagement: exportManagement,
        gensetBackfeedProtection:
          gensetDetails.gensetBackfeedProtectionStatus === "Y" ? true : false,
        gensetLSIEnable: gensetLSIEnable,
        loadShedding: loadShedding.loadShedding,
        utilityLSIEnable: utilityLSIEnable,
        gensetLoad: gensetLoad,
        maxExportPower: maxExportPower,
        pvCurtailmentExport: pvCurtailmentExport,
        batteryBufferingExport: batteryDetails.batteryBufferingExport,
        BESS: batteryDetails.batteryBufferingStatus === true ? newBESS : null,
        Genset: newGenset,
        reconnectionTimer: reconnectionTimers.reconnectionTimer,
        reconnection2Timer: reconnectionTimers.reconnection2Timer,
        loadPercentage:
          parseInt(gensetLoad) > 0
            ? (
                (parseFloat(gensetLoad) / parseFloat(maxGensetLoad)) *
                100
              ).toFixed(2)
            : 0.0,
        resources: resources,
        demandCharge: JSON.stringify(demandChargeData),
        programPage: false,
        bessHibernatingActivated: BESSHibernateEnabled === "Y" ? true : false,
        alarm: {
          alarmType: gridSettings.remoteMonitoringControl
            ? selectedAlaram
            : "NO_ALARM",
          emails:
            selectedAlaram === "NO_ALARM" ||
            !gridSettings.remoteMonitoringControl
              ? []
              : emailUsers,
        },
      };
      if (
        gridSettings.demandCharge === true ||
        gridSettings.tariffManagement === true
      ) {
        programDataJson.demandCharge = JSON.stringify(demandChargeOutput);
      } else {
        programDataJson.demandCharge = null;
      }

      if (batteryDetails.batteryBufferingStatus === true) {
        let BESSChargingNew = Object.assign({}, BESSCharging);

        BESSChargingNew.BESS_charging_limitation_enabled =
          BESSChargingEnabled === "Y" ? true : false;

        setBESSCharging(BESSChargingNew);
        programDataJson.bessChargingLimitation =
          JSON.stringify(BESSChargingNew);
      } else {
        programDataJson.bessChargingLimitation = null;
      }

      updateProgram(programDataJson);
    }
  };

  const updateProgram = programData => {
    apiSession
      .updateProgramData(programData)
      .then(response => {
        assignLoads(response);
        callSnackbar("success", "Program validated successfully");
        setLoading(false);
        localStorage.setItem("programDataModified", false);
        localStorage.setItem("programPage", false);
        configuredAll();
      })
      .catch(() => {
        callSnackbar("error", "Error in validation");
        setLoading(false);
      });
  };

  const saveDraftGenerateProgram = onDemand => {
    let resources = [];
    let resourcesLoad = [];
    let resourcesEm = [];
    let resourcesEs = [];
    let resourcesNEs = [];
    loads.forEach(LData => {
      resourcesLoad.push(LData);
    });
    loadList.emergencyList.forEach(EMData => {
      resourcesEm.push(EMData);
    });
    loadList.essentialList.forEach(ESData => {
      resourcesEs.push(ESData);
    });
    loadList.nonEssentialList.forEach(NESData => {
      resourcesNEs.push(NESData);
    });
    resources.push(...resourcesLoad);
    resources.push(...resourcesEm);
    resources.push(...resourcesEs);
    resources.push(...resourcesNEs);
    let newBESS = Object.assign({}, BESS);
    newBESS.changed = 1;
    let newGenset = Object.assign({}, genset);
    newGenset.changed = 1;
    let programDataJson = {
      projectId: sessionStorage.getItem("projectId"),
      siteGroup: siteGroup,
      gridDetectionTimer: gridDetails.gridDetectionTimer,
      lostDetectionTimer: gridDetails.lostDetectionTimer,
      gridOutrageDetection:
        gridDetails.gridOutrageDetectionCase === "CB" ? 1 : 2,
      pvCurtailment: pvCurtailment,
      pvBreakers: pvBreakers,
      batteryBuffering: batteryDetails.batteryBuffering,
      uc_dc: false,
      uc_tm: false,
      uc_sc: false,
      uc_no_export: false,
      exportManagement: exportManagement,
      gensetBackfeedProtection:
        gensetDetails.gensetBackfeedProtectionStatus === "Y" ? true : false,
      gensetLSIEnable: gensetLSIEnable,
      loadShedding: loadShedding.loadShedding,
      utilityLSIEnable: utilityLSIEnable,
      gensetLoad: gensetLoad,
      maxExportPower: maxExportPower,
      pvCurtailmentExport: pvCurtailmentExport,
      batteryBufferingExport: batteryDetails.batteryBufferingExport,
      BESS: batteryDetails.batteryBufferingStatus === true ? newBESS : null,
      Genset: newGenset,
      reconnectionTimer: reconnectionTimers.reconnectionTimer,
      reconnection2Timer: reconnectionTimers.reconnection2Timer,
      loadPercentage:
        parseInt(gensetLoad) > 0
          ? (
              (parseFloat(gensetLoad) / parseFloat(maxGensetLoad)) *
              100
            ).toFixed(2)
          : 0.0,
      resources: resources,
      demandCharge: JSON.stringify(demandChargeData),
      programPage: false,
      bessHibernatingActivated: BESSHibernateEnabled === "Y" ? true : false,
      alarm: {
        alarmType: gridSettings.remoteMonitoringControl
          ? selectedAlaram
          : "NO_ALARM",
        emails:
          selectedAlaram === "NO_ALARM" || !gridSettings.remoteMonitoringControl
            ? []
            : emailUsers,
      },
    };
    if (
      gridSettings.demandCharge === true ||
      gridSettings.tariffManagement === true
    ) {
      programDataJson.demandCharge = JSON.stringify(demandChargeOutput);
    } else {
      programDataJson.demandCharge = null;
    }

    if (batteryDetails.batteryBufferingStatus === true) {
      let BESSChargingNew = Object.assign({}, BESSCharging);

      BESSChargingNew.BESS_charging_limitation_enabled =
        BESSChargingEnabled === "Y" ? true : false;

      setBESSCharging(BESSChargingNew);
      programDataJson.bessChargingLimitation = JSON.stringify(BESSChargingNew);
    } else {
      programDataJson.bessChargingLimitation = null;
    }

    localStorage.setItem("draftSave", JSON.stringify(programDataJson));
    if (onDemand === true) {
      saveDraftProgramOnDemand(programDataJson);
    }
  };

  const saveDraftProgramOnDemand = data => {
    if (UserScope.access.saveProgram === true) {
      setLoading(true);
      apiSession
        .saveDraftProgram(data)
        .then(() => {
          callSnackbar("success", "Saved successfully");
          setLoading(false);
          localStorage.setItem("programDataModified", false);
        })
        .catch(() => {
          setLoading(false);
          callSnackbar("error", "Error in saving data");
        });
    }
  };

  const reset = () => getProgramData(sessionStorage.getItem("projectId"));

  //* snackbar

  const callSnackbar = useCallback((type, message) => {
    setSnackBar({ type, message });
    snackBarRef.current.open = true;
  }, []);

  const setAlertMessage = (title, isRoute, routePath, message) => {
    setAlert({
      title,
      isRoute,
      routePath,
      message,
      alertPopUpStatus: true,
    });
  };

  const closeAlertMessage = useCallback(() => {
    setAlert({
      message: "",
      alertPopUpStatus: false,
      title: "",
      isRoute: false,
      routePath: "",
    });
    if (alert.isRoute) {
      let { history } = props;
      history.push({
        pathname: `/${alert.routePath}`,
      });
    }
  }, [alert, props]);

  return (
    <div>
      <se-loading loading={loading}></se-loading>

      <se-container option="centered">
        {UserScope.access.saveProgram !== true && (
          <div className="col-sm-12 text-center mt-1">
            <span className="se-error-message-note text-center">
              {"Information: " +
                UserScope.role +
                " is not authorized to perform action on this page"}
            </span>
          </div>
        )}
        {UserScope.access.saveProgram && siteCreated && (
          <div className="col-sm-12 text-center mt-1 se-block-disabled">
            <span className="se-error-message-note text-center">
              {`Site already created for the project, no more modifications allowed`}
            </span>
          </div>
        )}

        <div
          className={`container mb-2 mt-2 ${
            siteCreated
              ? "se-block-disabled"
              : !UserScope.access.saveProgram
              ? "se-block-disabled"
              : ""
          }`}
        ></div>

        <div
          className={`container mb-2 mt-2 ${
            UserScope.access.saveProgram ? "" : "se-block-disabled"
          }`}
        >
          <DesignProgramProvider
            value={{
              gridSettings,
              configuredStatus,
              demandChargeData,
              demandChargeOutput,
              siteType,
              maxExportPower,
              batteryDetails,
              pvCurtailmentExport,
              utilityLSIEnable,
              loadsDisplay,
              loadShedding,
              loads,
              loadList,
              reconnectionTimers,
              defaultsMinMax,
              values,
              domain,
              gridDetails,
              gensetDetails,
              gensetLoad,
              maxGensetLoad,
              pvCurtailment,
              gensetLSIEnable,
              BESSChargingEnabled,
              BESSCharging,
              BESSHibernateEnabled,
              BESS,
              powerReservePercentageBESS,
              genset,
              powerReservePercentageGenset,
              templateSelected,
              architectureType,
              clientType,
              demandChargeDataCallback,
              exportManagementChange,
              configuredStatusModified,
              handleChange,
              handleCheckBoxChange,
              handlepPVOpeningPopup,
              loadSheddingChangeHandler,
              onDrag,
              onDrop,
              onDragFrom,
              onDragOver,
              handleRadioButtonChange,
              handleGensetOptimizationConfirm,
              openBessChargingpopup,
              openBessHibernatepopup,
              onUpdate,
              onChange,
              handleChangeBESS,
              handleChangePowerReservePercentage,
              handleChangeGenset,
              enableTemplatePopUp,
            }}
          >
            {gridSettings.remoteMonitoringControl && (
              <OperationUser
                siteGroup={siteGroup}
                UserScope={UserScope}
                siteUsers={siteUsers}
                handleChangeSiteGroup={handleChangeSiteGroup}
                addUserSite={addUserSite}
                removeSiteUserConfirmationPrompt={
                  removeSiteUserConfirmationPrompt
                }
              />
            )}

            {gridSettings.remoteMonitoringControl && (
              <Alarming
                siteType={siteType}
                architectureType={architectureType}
                selectedAlaram={selectedAlaram}
                UserScope={UserScope}
                emailUsers={emailUsers}
                alaramChangeHanlder={alaramChangeHanlder}
                addEmailToAlaram={addEmailToAlaram}
                removeUserFromAlarmStatus={removeUserFromAlarmStatus}
              />
            )}

            {siteType && <OnGridOptimization />}

            <div className="row mt-1 mb-2">
              <div className="col-md-12"></div>
            </div>

            {siteType && <OffGridManagement />}

            <div className="row mt-1 mb-2">
              <div className="col-md-12"></div>
            </div>

            {siteType && <DisabledUseCase gridSettings={gridSettings} />}

            {siteType && (
              <div className="row mt-2">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12  text-right">
                  {(gridSettings.demandCharge ||
                    gridSettings.tariffManagement) && (
                    <se-button
                      option="flat"
                      color="primary"
                      disabled={false}
                      onClick={saveNewTemplate}
                    >
                      Save Template
                    </se-button>
                  )}
                  <se-button
                    option="flat"
                    color="primary"
                    disabled={
                      UserScope.access.saveProgram === true ? false : true
                    }
                    onClick={() => saveDraftGenerateProgram(true)}
                  >
                    Save as Draft
                  </se-button>
                  <se-button
                    option="flat"
                    color="primary"
                    disabled={
                      UserScope.access.saveProgram === true ? false : true
                    }
                    onClick={validateProgram}
                  >
                    Validate Usecase
                  </se-button>
                  <se-button option="outline" onClick={reset}>
                    Reset
                  </se-button>
                </div>
              </div>
            )}
          </DesignProgramProvider>
        </div>

        {popupStatus.addUserPopUpStatus && (
          <SiteAddUser
            popUpstatus={popupStatus.addUserPopUpStatus}
            addUserSiteList={addUserToSiteList}
            closeAddUser={closeAddUser}
            message="Invite User(s) to the site"
          />
        )}

        {popupStatus.inviteUserToAlaramStatus && (
          <SiteAddUser
            popUpstatus={popupStatus.inviteUserToAlaramStatus}
            addUserSiteList={inviteUserToAlaramList}
            closeAddUser={closeInviteEmailToAlaram}
            message="Add User(s) to the Alarm"
          />
        )}

        <SelectTemplate
          isOpen={popupStatus.templatePopUpStatus}
          templates={templates}
          disableTemplatePopUp={disableTemplatePopUp}
          selectTemplate={selectTemplate}
        />

        <Snackbar snackBar={snackBar} ref={snackBarRef} />
        <AlertIcon alert={alert} onClose={closeAlertMessage} />

        <se-dialog
          id="dialog-complex-delete-confirm"
          open={popupStatus.deleteSiteUserConfirmPopUpStatus}
          can-backdrop="false"
        >
          <se-dialog-header>Confirmation</se-dialog-header>
          <se-dialog-content>
            <span className="se-font-14px">
              Do you confirm to delete invited site user ?
            </span>
          </se-dialog-content>
          <se-dialog-footer>
            <se-button onClick={removeSiteUserConfirm}>Yes</se-button>
            <se-button onClick={closeDeleteSiteUserPopUp}>No</se-button>
          </se-dialog-footer>
        </se-dialog>

        <se-dialog
          id="dialog-complex-delete-confirm"
          open={popupStatus.deleteUserFromAlarmStatus}
          can-backdrop="false"
        >
          <se-dialog-header>Confirmation</se-dialog-header>
          <se-dialog-content>
            <span className="se-font-14px">
              Do you confirm to delete added user ?
            </span>
          </se-dialog-content>
          <se-dialog-footer>
            <se-button onClick={removeEmailFromAlaram}>Yes</se-button>
            <se-button onClick={closeDeleteInviteUserpopup}>No</se-button>
          </se-dialog-footer>
        </se-dialog>

        <se-dialog
          id="dialog-complex-loadshedding-confirm"
          open={popupStatus.loadSheddingConfirmPopUpStatus}
          can-backdrop="false"
        >
          <se-dialog-header>Confirmation</se-dialog-header>
          <se-dialog-content>
            <span className="se-font-14px">
              Please ensure genset and/or BESS can feed inrush currents and
              continuous loads.
            </span>
          </se-dialog-content>
          <se-dialog-footer>
            <se-button onClick={cancelLoadSheddingConfirm}>Cancel</se-button>
            <se-button onClick={acceptLoadSheddingConfirm}>OK</se-button>
          </se-dialog-footer>
        </se-dialog>

        <se-dialog
          id="dialog-complex-loadshedding-confirm"
          open={popupStatus.loadSheddingConfirmTruePopUpStatus}
          can-backdrop="false"
        >
          <se-dialog-header>Confirmation</se-dialog-header>
          <se-dialog-content>
            <span className="se-font-14px">
              Do you want to activate Load shedding ?
            </span>
          </se-dialog-content>
          <se-dialog-footer>
            <se-button onClick={cancelLoadSheddingTrueConfirm}>No</se-button>
            <se-button onClick={acceptLoadSheddingTrueConfirm}>Yes</se-button>
          </se-dialog-footer>
        </se-dialog>

        <se-dialog
          id="dialog-complex-loadshedding-confirm"
          open={popupStatus.gensetOptimisationConfirmPopUpStatus}
          can-backdrop="false"
        >
          <se-dialog-header>Confirmation</se-dialog-header>
          <se-dialog-content>
            <span className="se-font-14px">
              Do you want to activate genset optimization and backfeed
              protection?
            </span>
          </se-dialog-content>
          <se-dialog-footer>
            <se-button onClick={cancelGensetOptimisation}>No</se-button>
            <se-button onClick={confirmGensetOptimisation}>Yes</se-button>
          </se-dialog-footer>
        </se-dialog>

        <se-dialog
          id="dialog-complex-loadshedding-confirm"
          open={popupStatus.bessChargingConfirmPopUpStatus}
          can-backdrop="false"
        >
          <se-dialog-header>Confirmation</se-dialog-header>
          <se-dialog-content>
            <span className="se-font-14px">
              Do you want to activate BESS charging limitation?
            </span>
          </se-dialog-content>
          <se-dialog-footer>
            <se-button onClick={cancelBessCharging}>No</se-button>
            <se-button onClick={confirmBessCharging}>Yes</se-button>
          </se-dialog-footer>
        </se-dialog>

        <se-dialog
          id="dialog-complex-loadshedding-confirm"
          open={popupStatus.besshibernateConfirmPopUpStatus}
          can-backdrop="false"
        >
          <se-dialog-header>Confirmation</se-dialog-header>
          <se-dialog-content>
            <span className="se-font-14px">
              If hibernating is activated, BESS will stop when SoC becomes lower
              than SoC Min. Manual action is needed to restart BESS. Do you want
              to activate BESS hibernating ?
            </span>
          </se-dialog-content>
          <se-dialog-footer>
            <se-button onClick={cancelBessHibernate}>No</se-button>
            <se-button onClick={confirmBessHibernate}>Yes</se-button>
          </se-dialog-footer>
        </se-dialog>

        <se-dialog
          id="dialog-complex-loadshedding-confirm"
          open={popupStatus.utilityPvOpeningConfirmpopUpSattus}
          can-backdrop="false"
        >
          <se-dialog-header>Confirmation</se-dialog-header>
          <se-dialog-content>
            <span className="se-font-14px">
              This option requires a motorized PV breaker, Do you confirm ?
            </span>
          </se-dialog-content>
          <se-dialog-footer>
            <se-button onClick={cancelUtilityPvopenPopUp}>No</se-button>
            <se-button onClick={confirmUtilityPVOpen}>Yes</se-button>
          </se-dialog-footer>
        </se-dialog>

        <se-dialog
          id="dialog-complex-loadshedding-confirm"
          open={popupStatus.gensetPvOpeningConfirmpopUpSattus}
          can-backdrop="false"
        >
          <se-dialog-header>Confirmation</se-dialog-header>
          <se-dialog-content>
            <span className="se-font-14px">
              This option requires a motorized PV breaker, Do you confirm ?
            </span>
          </se-dialog-content>
          <se-dialog-footer>
            <se-button onClick={cancelGensetPvopenPopUp}>No</se-button>
            <se-button onClick={confirmGensetPVOpen}>Yes</se-button>
          </se-dialog-footer>
        </se-dialog>

        <se-dialog
          id="dialog-complex-delete-confirm"
          open={popupStatus.newTemplateConfirmPopUpStatus}
          can-backdrop="false"
        >
          <se-dialog-header>
            Do you want to save this template?
          </se-dialog-header>
          <se-dialog-content>
            <div className="col-md-12">
              <div className="form-group row mb-0">
                <label
                  htmlFor="copyProjectName"
                  className="col-sm-9 col-form-label ml-3"
                >
                  Enter new template name
                </label>
              </div>
              <div className="form-group">
                <div className="col-sm-12">
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control form-control-sm"
                    id="copyProjectName"
                    name="copyProjectName"
                    maxLength="50"
                    value={newTemplateName}
                    onChange={handlenewTemplate}
                  />
                  <div
                    className="invalid-feedback-custom"
                    style={newTemplateName ? { display: "none" } : {}}
                  >
                    Template Name is mandatory
                  </div>
                  <div
                    className="invalid-feedback-custom"
                    style={
                      templates.filter(
                        template => template.title === newTemplateName
                      ).length
                        ? {}
                        : { display: "none" }
                    }
                  >
                    {newTemplateName} exsits, provide an unique name
                  </div>
                </div>
              </div>
            </div>
            <br></br>
          </se-dialog-content>
          <se-dialog-footer>
            <se-button onClick={closeNewTemplatePopUp}>Cancel</se-button>
            <se-button
              onClick={saveTemplate}
              disabled={
                [newTemplateName].includes("") ||
                Boolean(
                  templates.filter(
                    template => template.title === newTemplateName
                  ).length
                )
              }
            >
              OK
            </se-button>
          </se-dialog-footer>
        </se-dialog>
      </se-container>
    </div>
  );
};

export default DesignProgramHook;
